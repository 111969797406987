import React, { Component } from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"

import { MDXRenderer } from "gatsby-plugin-mdx"
import s from "../projectStyles.module.scss"

class MusicVideos extends Component {
   render() {
      const animationStatus = {
         transitionStatus: this.props.transitionStatus,
         entry: this.props.entry,
         exit: this.props.exit,
      }

      const footerContent = {
         C2As: [
            {
               text: "Check out some of my recent work here.",
               link: "about-me",
            },
            { text: "Here are some other projects", link: "projects" },
         ],
         styles: {
            backgroundColor: "transparent",
         },
      }
      return (
         <div className={`mainContainer ${s.markdownPage}`}>
            <Layout
               title="Music Video Direction"
               superTitle="Mr BJ Jackson / Projects"
               pageStyles={s}
               animationStatus={animationStatus}
               footerContent={footerContent}
               isProjectPage={true}
            >
               <MDXRenderer frontmatter={this.props.data.mdx.frontmatter}>
                  {this.props.data.mdx.body}
               </MDXRenderer>
            </Layout>
         </div>
      )
   }
}

export default MusicVideos

export const data = graphql`
   query {
      mdx(frontmatter: { title: { eq: "Music Videos" } }) {
         body
         frontmatter {
            title
         }
      }
   }
`
